// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["context", "button"];

  get hiddenClass () {
    return this.data.get("hidden-class");
  }

  toggle () {
    if (this.hasHiddenClass()) {
      this.slideDown();
    } else {
      this.slideUp();
    }
  }

  slideDown () {
    this.adjustText();
    this.toggleHiddenClass();
    this.contextTarget.style.height = "auto";
    const height = `${this.contextTarget.clientHeight}px`;
    this.resetHeight();

    setTimeout(() => {
      this.contextTarget.style.height = height;
    }, 0);
  }

  slideUp () {
    this.adjustText();
    this.resetHeight();
    this.contextTarget.addEventListener(
      "transitionend",
      () => {
        this.toggleHiddenClass();
      },
      { once: true },
    );
  }

  hasHiddenClass () {
    return this.contextTarget.classList.contains(this.hiddenClass);
  }

  toggleHiddenClass () {
    this.contextTarget.classList.toggle(this.hiddenClass);
  }

  resetHeight () {
    this.contextTarget.style.height = "0px";
  }

  buttonLabel () {
    return this.hasHiddenClass() ? "Less context" : "More context";
  }

  adjustText () {
    this.buttonTarget.innerText = this.buttonLabel();
  }
}
