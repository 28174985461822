import { Controller } from "@hotwired/stimulus";

// Note: Forfeit dialog has similar behavior as the rejection dialog (modal with text area), but its implementation is
// simpler. If you need to build another modal with a text area, please follow the example of the forfeit dialog,
// not the rejection dialog. See:
// app/frontend/components/admin/cap_table_change_requests/forfeit_dialog/component.rb
// app/frontend/javascript/controllers/cap_table_change_requests/forfeitures_controller.js
export default class extends Controller {
  static targets = [
    "companyName",
    "dialogCancelButton",
    "dialogRejectButton",
    "employeeBuybackWarning",
    "employeeName",
    "eventType",
    "rejectDialog",
    "rejectForm",
    "textArea",
  ];

  connect () {
    this.addRejectEventListeners();
  }

  addRejectEventListeners () {
    const rejectButtons = document.getElementsByClassName("reject-pending-request-button");

    Array.from(rejectButtons).forEach((button) => {
      button.addEventListener("click", () => this.setDialogData(button));
    });
  }

  setDialogData (button) {
    const currentEl = button;
    let previousSibling = currentEl.previousElementSibling;

    while (previousSibling) {
      const siblingId = previousSibling.id;
      switch (siblingId) {
        case "event_type":
          this.setEventTypeData(previousSibling);
          break;
        case "company_name":
          this.setCompanyName(previousSibling);
          break;
        case "event_id":
          this.setFormSubmissionPath(previousSibling);
          break;
        case "employee_name":
          this.setEmployeeName(previousSibling);
          break;
        case "show_employee_buyback_warning":
          this.handleEmployeeBuybackWarning(previousSibling);
          break;
        default:
          break;
      }

      previousSibling = previousSibling.previousElementSibling;
    }
  }

  setCompanyName (element) {
    const companyName = element.value;

    this.companyNameTarget.innerHTML = companyName;
  }

  setEmployeeName (element) {
    const employeeName = element.value;

    this.employeeNameTarget.innerHTML = employeeName;
  }

  setEventTypeData (element) {
    const eventType = element.value;

    this.eventTypeTarget.innerHTML = eventType;
  }

  handleEmployeeBuybackWarning (element) {
    if (element.value === "true") {
      this.employeeBuybackWarningTarget.classList.remove("hidden");
    } else {
      this.employeeBuybackWarningTarget.classList.add("hidden");
    }
  }

  setFormSubmissionPath (element) {
    const eventId = element.value;
    const submissionPath = `/cap_table/event/rejections/${eventId}`;

    this.rejectFormTarget.action = submissionPath;
  }

  hideDialogIfValid () {
    const reason = this.textAreaTarget.value;

    if (reason) {
      this.rejectDialogTarget.hide();
    }
  }

  showRejectDialog () {
    this.rejectDialogTarget.show();
  }

  hideRejectDialog () {
    this.rejectDialogTarget.hide();
  }

  resetTextAreaValue () {
    this.textAreaTarget.value = "";
  }
}
