import { Controller } from "@hotwired/stimulus";
import { employeeCertificates } from "./mixins/employee_certificates";

export default class extends Controller {
  static targets = ["userInput", "certInput"];

  connect () {
    employeeCertificates(this);
    this.updateUserCerts();
  }

  changeUser () {
    this.checkForAndRemoveErrorState();
    this.updateUserCerts();
  }

  updateUserCerts () {
    const userId = this.userInputTarget.value;
    const elem = this.certInputTarget;

    if (this.isUserBlank()) {
      this.resetBlankUserCertOptions(elem);

      return;
    }

    Teamshares.Rails.ajax({
      type: "GET",
      dataType: "script",
      url: elem.dataset.certsPath,
      data: `user_id=${userId}`,
      success: (_data, _status, xhr) => {
        elem.innerHTML = xhr.response;
      },
    });
  }
}
