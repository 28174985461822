import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal", "trigger"];

  connect () {
    this.boundshowModal = this.showModal.bind(this);
    this.triggerTarget.addEventListener("click", this.boundshowModal);
  }

  disconnect () {
    this.triggerTarget.removeEventListener("click", this.boundshowModal);
  }

  showModal (e) {
    e.preventDefault();
    this.modalTarget.show();
  }
}
