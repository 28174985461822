import { Controller } from "@hotwired/stimulus";

export default class PreferredShareholderConfirmationController extends Controller {
  static targets = [
    "confirmationDialog",
    "newShareholderName",
    "newShareholderSelect",
  ];

  connect () {
    this.handleNewShareholderChange();
  }

  cancelPreferredShareholderSubmit () {
    this.confirmationDialogTarget.hide();
  }

  showConfirmationDialog () {
    this.confirmationDialogTarget.show();
  }

  handleNewShareholderChange () {
    const selectedIndex = this.newShareholderSelectTarget.selectedIndex;
    const newPreferredShareholder = this.newShareholderSelectTarget.options[selectedIndex].text;

    this.newShareholderNameTarget.innerHTML = newPreferredShareholder;
  }
}
