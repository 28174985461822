import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["output", "input", "preview"];

  readURL () {
    const input = this.inputTarget;
    const output = this.outputTarget;
    const preview = this.previewTarget;
    const file = input.files && input.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = function () {
        const image = new Image();
        image.title = file.name;
        image.id = "avatar";
        image.classList = "h-20 w-20 rounded-full object-cover";
        image.src = reader.result;
        image.setAttribute("data-image-preview-target", "output");
        preview.replaceChild(image, output);
      };
      reader.readAsDataURL(file);
    }
  }
}
