import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    companyAbbreviation: String,
    linkToken: String,
  };

  connect () {
    const linkToken = this.linkTokenValue;

    // eslint-disable-next-line no-undef
    MergeLink.initialize({
      linkToken,
      onSuccess: (publicToken) => this.onSuccess(publicToken),
      onExit: () => this.onExit(),
      shouldSendTokenOnSuccessfulLink: true,
    });

    // eslint-disable-next-line no-undef
    MergeLink.openLink();
  }

  onSuccess (publicToken) {
    fetch(`/companies/${this.companyAbbreviationValue}/company_integrations`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").getAttribute("content"),
      },
      body: JSON.stringify({ public_token: publicToken }),
    });
  }

  onExit () {
    location.replace(location.href);
  }
}
