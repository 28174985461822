import { Controller } from "@hotwired/stimulus";

export default class DepartureDetailsController extends Controller {
  static targets = [
    "reason",
    "employee",
    "isRetirementAge",
    "isRetirementAgeInput",
    "hasTenure",
    "hasTenureInput",
    "presidentPayoutEnabled",
  ];

  initialize () {
    this.handleReasonChange();
    this.handleEmployeeChange();
  }

  handleReasonChange () {
    const reasonValue = this.reasonTarget.value;
    if (reasonValue === "Retirement") {
      this.hasTenureTarget.classList.remove("hidden");
      this.isRetirementAgeTarget.classList.remove("hidden");
    } else {
      this.hasTenureTarget.classList.add("hidden");
      this.isRetirementAgeTarget.classList.add("hidden");

      this.isRetirementAgeInputTargets.forEach(input => {
        this.clearCheckedTarget(input);
      });

      this.hasTenureInputTargets.forEach(input => {
        this.clearCheckedTarget(input);
      });
    }
  }

  handleEmployeeChange () {
    if (this.presidentPayoutEnabledTarget.value === "false") {
      return;
    }

    const role = this.employeeTarget.selectedOptions[0].role;
    if (role === "president") {
      if (this.reasonTarget.value === "Retirement") {
        this.reasonTarget.selectedIndex = 0;
        this.handleReasonChange();
      }
      this
        .reasonTarget
        .querySelector("option[value='Retirement']")
        .remove();
    } else {
      const existingOption = this.reasonTarget.querySelector("option[value='Retirement']");
      if (!existingOption) {
        const retirementOption = document.createElement("option");
        retirementOption.value = "Retirement";
        retirementOption.text = "Retirement";
        this.reasonTarget.appendChild(retirementOption);
      }
    }
  }

  clearCheckedTarget (input) {
    input.checked = false;
  }
}
