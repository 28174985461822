import { Controller } from "@hotwired/stimulus";

export default class ConfirmationController extends Controller {
  static targets = [
    "confirmationPrompt",
    "confirmationCancelButton",
  ];

  cancelSubmitEmployeeDeparture () {
    this.confirmationPromptTarget.hide();
  }
}
