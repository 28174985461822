import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submit"];
  static values = {
    externalSubmitId: String,
    externalRequiredFields: Boolean,
    showErrors: Boolean,
  };

  connect () {
    /* global HTMLFormElement */
    this.form = this.element instanceof HTMLFormElement ? this.element : this.element.querySelector("form");
    if (this.form) {
      this.form.addEventListener("click", this.validateRequiredFields.bind(this));
      this.form.addEventListener("change", this.validateRequiredFields.bind(this));
      this.form.addEventListener("input", this.validateRequiredFields.bind(this));
      this.form.addEventListener("keyup", this.validateRequiredFields.bind(this));
      this.form.addEventListener("reset", this.validateRequiredFields.bind(this));

      this.validateRequiredFields();
    }
  }

  disconnect () {
    if (this.form) {
      this.form.removeEventListener("click", this.validateRequiredFields.bind(this));
      this.form.removeEventListener("change", this.validateRequiredFields.bind(this));
      this.form.removeEventListener("input", this.validateRequiredFields.bind(this));
      this.form.removeEventListener("keyup", this.validateRequiredFields.bind(this));
      this.form.removeEventListener("reset", this.validateRequiredFields.bind(this));
    }
  }

  validateRequiredFields () {
    const requiredFields = this.element.querySelectorAll("[required]");
    const externalRequiredFieldsValidated = this.hasExternalRequiredFieldsValue ? this.externalRequiredFieldsValue : true;
    const isValid = externalRequiredFieldsValidated && Array.from(requiredFields).every((field) => this.isValid(field));

    if (this.showErrorsValue) {
      requiredFields.forEach((field) => {
        if (this.isValid(field)) {
          field.classList.remove("is-invalid");
          field.classList.add("is-valid");
          field.parentElement.classList.add("field_without_errors");
          field.parentElement.classList.remove("field_with_errors");
          field.setAttribute("aria-invalid", "false");
        } else {
          field.setAttribute("aria-invalid", "true");
          field.classList.remove("is-valid");
          field.classList.add("is-invalid");
          field.parentElement.classList.add("field_with_errors");
          field.parentElement.classList.remove("field_without_errors");
        }
      });
    }

    const submitButton = this.hasSubmitTarget ? this.submitTarget : document.getElementById(this.externalSubmitIdValue);
    if (submitButton) {
      submitButton.disabled = !isValid;

      if (submitButton.parentElement.nodeName === "SL-TOOLTIP") {
        submitButton.parentElement.disabled = isValid;
      }
    }
  }

  isValid (input) {
    return !this.isEmpty(input) && !this.isWrongLength(input);
  }

  isEmpty (input) {
    return input.value.trim().length === 0;
  }

  isWrongLength (input) {
    const minLength = input.getAttribute("minlength") || input.getAttribute("min");
    const maxLength = input.getAttribute("maxlength") || input.getAttribute("max");
    const length = input.value.trim().length;

    if (minLength && (length < parseInt(minLength))) {
      return true;
    } else if (maxLength && (length > parseInt(maxLength))) {
      return true;
    } else {
      return false;
    }
  }
}
