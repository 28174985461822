import { Controller } from "@hotwired/stimulus";
import MicroModal from "micromodal";

export default class extends Controller {
  initialize () {
    if (this.data.get("showModal") === "true") {
      setTimeout(() => { MicroModal.show("info_modal"); }, 1000);
    }
  }
}
