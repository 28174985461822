import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect () {
    if (this.element.dataset.show === "true") {
      // Wait a frame in order to get the opening animation
      requestAnimationFrame(() => {
        this.element.show();
      });
    }
  }

  close = () => {
    this.element.dataset.show = "false";
    this.element.hide();
  };

  saveAnyway = () => {
    this.close();
    // Modify the hidden input so that the form action bypasses the Google Validation API
    const toggle = document.getElementById("validateAddressToggle");
    toggle.value = "false";
    const form = document.getElementById("userInfoForm");
    form.submit();
  };
}
