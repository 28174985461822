import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect () {
    window.addEventListener("keydown", this.onKeyDown);
  }

  disconnect () {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = (e) => {
    if (e.code === "KeyD" && e.ctrlKey && e.shiftKey) {
      this.element.open = !this.element.open;
    }
  };

  handleNavigationSelect = (e) => {
    e.preventDefault();
    window.location = e.currentTarget.value;
  };
}
