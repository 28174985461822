import { Controller } from "@hotwired/stimulus";
import { employeeCertificates } from "./mixins/employee_certificates";

export default class extends Controller {
  static targets = [
    "certificateInput", "certificateDescription", "certificatesTotalShares",
    "currentTeamsharesTotal", "newTeamsharesTotal", "userInput",
  ];

  connect () {
    employeeCertificates(this);
    this.updateUserCerts();
    this.disableUserInput();
  }

  changeUser () {
    this.updateUserCerts();
    this.checkForAndRemoveErrorState();
  }

  changeCertificate () {
    this.updatePercentages();
  }

  disableUserInput () {
    if (this.userInputTarget.options.length === 1) {
      this.userInputTarget.classList.add("disable-but-submit");
    }
  }

  toggleCertDescription () {
    if (this.isUserBlank()) {
      this.certificateDescriptionTarget.classList.add("hidden");
    } else {
      this.certificateDescriptionTarget.classList.remove("hidden");
    }
  }

  toggleCertInput () {
    if (this.certificateInputTarget.options.length === 1) {
      this.certificateInputTarget.classList.add("disable-but-submit");
    } else {
      this.certificateInputTarget.classList.remove("disable-but-submit");
    }
  }

  updateUserCerts () {
    const userId = this.userInputTarget.value;
    const elem = this.certificateInputTarget;
    if (this.isUserBlank()) {
      this.resetBlankUserCertOptions(elem);
      this.toggleCertDescription();
      this.toggleCertInput();

      return;
    }

    Teamshares.Rails.ajax({
      type: "GET",
      dataType: "script",
      url: this.certificateInputTarget.dataset.certsPath,
      data: `user_id=${userId}`,
      success: (_data, _status, xhr) => {
        this.certificateInputTarget.innerHTML = xhr.response;
        this.toggleCertDescription();
        this.toggleCertInput();
        this.updatePercentages();
      },
    });
  }

  updatePercentages () {
    Teamshares.Rails.ajax({
      type: "GET",
      dataType: "script",
      url: this.certificateInputTarget.dataset.percentagesPath,
      data: `stock_certificate_id=${this.certificateInputTarget.value}`,
      success: (data, _status, _xhr) => {
        this.certificatesTotalSharesTarget.innerHTML = data.total_vested_shares;
        this.currentTeamsharesTotalTarget.innerHTML = parseFloat(data.current_teamshares_preferred_shares);
        this.newTeamsharesTotalTarget.innerHTML = parseFloat(data.total_vested_shares) + parseFloat(data.current_teamshares_preferred_shares);
      },
    });
  }
}
