import { Controller } from "@hotwired/stimulus";
import { employeeCertificates } from "./mixins/employee_certificates";
import dayjs from "dayjs";

export default class extends Controller {
  static targets = ["userInput", "certInput", "effectiveDateInput"];

  connect () {
    employeeCertificates(this);
    this.updateUserCerts();
  }

  changeUser () {
    this.checkForAndRemoveErrorState();
    this.updateUserCerts();
  }

  updateUserCerts () {
    const userId = this.userInputTarget.value;
    const elem = this.certInputTarget;

    if (this.isUserBlank()) {
      this.resetBlankUserCertOptions(elem);

      return;
    }

    Teamshares.Rails.ajax({
      type: "GET",
      dataType: "script",
      url: elem.dataset.certsPath,
      data: `user_id=${userId}&event_type=cancel`,
      success: (_data, _status, xhr) => {
        elem.innerHTML = xhr.response;
        this.changeCertificate();
      },
    });
  }

  changeCertificate () {
    const selectedOption = this.certInputTarget.options[this.certInputTarget.selectedIndex];
    const isSigned = selectedOption.dataset.isSigned === "true";
    const vestingStartDate = selectedOption.dataset.vestingStartDate;

    if (isSigned) {
      this.effectiveDateInputTarget.disabled = false;
      // Current date date in NY
      this.effectiveDateInputTarget.value = dayjs().tz("America/New_York").toISOString().split("T")[0];
    } else {
      this.effectiveDateInputTarget.disabled = true;
      this.effectiveDateInputTarget.value = vestingStartDate;
    }
  }
}
