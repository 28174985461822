import { Controller } from "@hotwired/stimulus";

/*
This controller allows a Shoelace dropdown to navigate the browser
to a URL specified by the data-link attribute on each menu item.
*/
export default class extends Controller {
  handleSelect = ({ detail }) => {
    const link = detail.item.getAttribute("data-link");
    if (link) {
      window.location = link;
    }
  };
}
