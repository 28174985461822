import { Controller } from "@hotwired/stimulus";

const partition = (arr, criteria) => ([
  arr.filter(criteria),
  arr.filter((item) => !criteria(item)),
]);

const getInputValue = (input) => parseFloat(input.value.replace(/[^\d.]/g, ""), 10) || 0;
const sumInputValues = (inputs) => inputs.reduce((sum, input) => sum + getInputValue(input), 0);

const preferredSharesCount = 9_999;

export default class extends Controller {
  static targets = [
    "addButton",
    "companySetup",
    "commonSharesOnly",
    "currentCommonShares",
    "issued",
    "nextStepButton",
    "preferredSharesOnly",
    "remainingInPool",
    "rowTemplate",
    "total",
  ];

  static values = {
    poolSize: Number,
    indexPlaceholder: String,
  };

  connect () {
    this.setInputCollections();
    this.addAllEventListeners();
    this.setInPoolStatusForRow();
    this.recalculateRunningTotals();
    this.disablePresidentKeyCheckbox();

    // Rails expects has_many associations in a dynamic, nested form to have
    // incrementing child indices. Normally this index would increment by 1 for each
    // additional record, but as long as the index is increasing, Rails knows how to save
    // these child objects and associate them with their parent.
    // Here, we start at 999 to give us a stable initial value for testing (works as long as we don't have a thousand items in any existing form)
    this.curIndex = 999;
  }

  disconnect () {
    this.removeAllEventListeners();
  }

  disablePresidentKeyCheckbox () {
    this.handleCompanySetupRoleChange();
  }

  setInputCollections () {
    this.numSharesInputs = Array.from(this.element.querySelectorAll(".js-num-shares-input"));
    this.sharesTotalInputs = Array.from(this.element.querySelectorAll(".js-shares-total"));
    this.inputs = Array.from(this.element.querySelectorAll(".js-new-employee-row .input:not(.numeric_with_commas)"));

    this.setRoleInputCollections();
  }

  setRoleInputCollections () {
    this.roleInputs = Array.from(this.element.querySelectorAll(".js-role-input"));
  }

  setRoleInputEventListeners () {
    this.roleInputs.forEach((input) => {
      input.addEventListener("change", this.handleCompanySetupRoleChange.bind(this));
      input.addEventListener("change", this.setInPoolStatusForRow.bind(this));
      input.addEventListener("change", this.recalculateRunningTotals.bind(this));
    });
  }

  resetCompanySetupInputEventListeners () {
    const deepClone = true;

    this.roleInputs.forEach((input) => {
      const clone = input.cloneNode(deepClone);
      clone.value = input.value;

      input.replaceWith(clone);
    });

    this.setRoleInputCollections();
    this.setRoleInputEventListeners();
  }

  addAllEventListeners () {
    this.numSharesInputs.forEach((input) => {
      input.addEventListener("keyup", this.recalculateRunningTotals.bind(this));
    });

    this.sharesTotalInputs.forEach((input) => {
      input.addEventListener("keyup", this.recalculateRunningTotals.bind(this));
    });

    this.inputs.forEach((input) => {
      input.addEventListener("change", this.resetErrorsOnChange.bind(this));
      input.addEventListener("click", this.resetErrorsOnChange.bind(this));
    });

    this.setRoleInputEventListeners();
  }

  removeAllEventListeners () {
    this.numSharesInputs.forEach((input) => {
      input.removeEventListener("keyup", this.recalculateRunningTotals.bind(this));
    });

    this.sharesTotalInputs.forEach((input) => {
      input.removeEventListener("keyup", this.recalculateRunningTotals.bind(this));
    });

    this.roleInputs.forEach((input) => {
      input.removeEventListener("change", this.setInPoolStatusForRow.bind(this));
      input.removeEventListener("change", this.recalculateRunningTotals.bind(this));
      input.removeEventListener("change", this.handleCompanySetupRoleChange.bind(this));
    });

    this.inputs.forEach((input) => {
      input.removeEventListener("change", this.resetErrorsOnChange.bind(this));
      input.removeEventListener("click", this.resetErrorsOnChange.bind(this));
    });
  }

  addRow (event) {
    event.preventDefault();

    const regex = new RegExp(this.indexPlaceholderValue, "g");
    const content = this.rowTemplateTarget.innerHTML.replace(regex, ++this.curIndex);

    if (this.hasCompanySetupTarget) {
      const employeeRows = document.querySelectorAll(".js-new-employee-row");
      const lastEmployeeRow = employeeRows[employeeRows.length - 1];

      lastEmployeeRow.insertAdjacentHTML("afterend", content);
    } else {
      this.addButtonTarget.insertAdjacentHTML("beforebegin", content);
    }

    this.removeAllEventListeners();
    this.setInputCollections();
    this.addAllEventListeners();
    this.setInPoolStatusForRow();

    this.resetCompanySetupInputEventListeners();
    this.handleCompanySetupRoleChange();
  }

  destroyRecord (event) {
    event.preventDefault();
    const el = event.currentTarget;
    const url = el.getAttribute("data-url");
    const id = el.getAttribute("data-id");

    Teamshares.Rails.ajax({
      url,
      type: "DELETE",
      success: (_data, _status, _xhr) => {
        this.deleteRow(event);
      },
      error: (data) => {
        document.getElementById(`delete-record-error${id}`).innerHTML = data;
      },
    });

    this.resetCompanySetupInputEventListeners();
  }

  deleteRow (event) {
    this.removeAllEventListeners();

    const el = event.target;
    const row = el.closest(".js-new-employee-row");
    row.remove();

    this.setInputCollections();
    this.addAllEventListeners();
    this.recalculateRunningTotals();

    this.resetCompanySetupInputEventListeners();
  }

  recalculateRunningTotals () {
    const [inPool, nonPool] = partition(this.numSharesInputs, (input) => input.dataset.inPool === "true");
    const issuedInPool = sumInputValues(inPool);
    const issuedNonPool = sumInputValues(nonPool);
    const issued = issuedInPool + issuedNonPool;
    const total = this.poolSizeValue + issuedNonPool;
    let remaining = 0;

    if (this.hasPreferredSharesOnlyTarget) {
      this.poolSizeValue = preferredSharesCount;
      remaining = this.poolSizeValue - issuedNonPool;
      this.nextStepButtonTarget.disabled = remaining !== 0;
    } else if (this.hasCommonSharesOnlyTarget) {
      this.poolSizeValue = getInputValue(this.currentCommonSharesTarget);
      remaining = this.poolSizeValue - issuedInPool;
      this.nextStepButtonTarget.disabled = remaining < 0;
    } else {
      // Were in the old form
      remaining = this.poolSizeValue - issuedInPool;
      this.issuedTarget.innerHTML = issued.toLocaleString();
      this.totalTarget.innerHTML = total.toLocaleString();
    }

    this.remainingInPoolTarget.innerHTML = remaining.toLocaleString();
    this.remainingInPoolTarget.classList[remaining < 0 ? "add" : "remove"]("text-red-500");
  }

  resetErrorsOnChange (scope) {
    const parent = scope?.target?.parentElement;
    // remove error
    parent?.querySelector(".error")?.remove();
    // remove red box on input
    parent?.classList.remove("field_with_errors");
  }

  setInPoolStatusForRow () {
    this.roleInputs.forEach((input) => {
      let inPool = true;
      const roleValue = input.value;
      const parentRowDiv = input.closest(".js-new-employee-row");
      const numSharesInput = parentRowDiv.querySelector(".js-num-shares-input");

      if (roleValue === "former_owner") {
        inPool = false;
      }

      if (roleValue === "teamshares_preferred") {
        inPool = false;
      }

      numSharesInput.setAttribute("data-in-pool", inPool);
    });
  }

  handleCompanySetupRoleChange () {
    this.roleInputs.forEach((input) => {
      const parentDiv = input.closest(".employee-row.js-new-employee-row");

      // This occurs when using the old employments setup form
      // to be removed after full feature release
      if (!parentDiv) {
        return;
      }

      const isPresident = input.value === "president";
      const checkbox = parentDiv.querySelector("input[type='checkbox']");

      if (checkbox) {
        checkbox.disabled = isPresident ? "disabled" : null;

        if (isPresident) {
          checkbox.classList.add("disabled");
        } else {
          checkbox.classList.remove("disabled");
        }
      }
    });
  }
}
