import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["counter"];

  connect () {
    this.updateCounter();
    this.element.addEventListener("input", this.updateCounter.bind(this));
  }

  disconnect () {
    this.element.removeEventListener("input", this.updateCounter.bind(this));
  }

  updateCounter () {
    if (!this.element.hasAttribute("maxlength")) return;

    const length = this.element.value.length;
    this.element.contextNote = `${length}/${this.element.getAttribute("maxlength")}`;
  }
}
