import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["uploadButton", "markCompleteButton"];

  connect () {
    this.markCompleteButtonTarget.disabled = true;
    this.pollForText();
  }

  pollForText () {
    this.interval = setInterval(() => {
      if (this.elementContainsText("Uploaded files can’t be removed")) {
        this.handleTextFound();
      }
    }, 1000);
  }

  elementContainsText (text) {
    return document.body.innerText.includes(text);
  }

  handleTextFound () {
    clearInterval(this.interval);
    this.markCompleteButtonTarget.disabled = false;
  }

  disconnect () {
    clearInterval(this.interval);
  }
}
