import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dropZone", "files", "fileDetails", "fileInput", "fileList", "uploadButton", "uploadedFileList", "uploadedFileCount", "uploadContainer", "uploadedFilesContainer"];

  static values = { uploadedFiles: Array, uploadedFilesAgain: String };

  connect () {
    this.setDragDropEffects();
    this.addUploadedFiles();
  }

  addUploadedFiles () {
    this.uploadedFilesValue.forEach((file) => {
      this.addFileToList(file);

      const listItem = document.getElementById(`file_upload_${file.name}`);
      this.moveFileToUploadedList(listItem);
    });
    this.updateFileCount();
    this.toggleUploadContainer();
  }

  setDragDropEffects () {
    document.addEventListener("dragover", (event) => {
      event.preventDefault();
      event.dataTransfer.dropEffect = "move";
    });
  }

  dragover (event) {
    event.preventDefault();
    this.dropZoneTarget.classList.add("bg-blue-50", "border-blue-700");
  }

  dragleave (event) {
    event.preventDefault();
    this.dropZoneTarget.classList.remove("bg-blue-50", "border-blue-700");
  }

  addFiles () {
    // Grab some references for later
    const originalInput = this.fileInputTarget;
    const originalParent = originalInput.parentNode;

    // Create an element that contains our input element
    const selectedFile = document.createElement("div");
    selectedFile.append(originalInput);

    // Create label (the visible part of the new input element) with the name of
    // the selected file.
    const labelNode = document.createElement("label");
    const textElement = document.createTextNode(originalInput.files[0].name);
    labelNode.appendChild(textElement);
    selectedFile.appendChild(labelNode);

    // Add the selected file to the list of selected files
    this.filesTarget.append(selectedFile);

    // Create a new input field to use going forward
    const newInput = originalInput.cloneNode();

    // Clear the filelist - some browsers maintain the filelist when cloning,
    // others don't
    newInput.value = "";

    // Add it to the DOM where the original input was
    originalParent.append(newInput);
  }

  drop (event) {
    event.preventDefault();
    this.dropZoneTarget.classList.remove("bg-blue-50", "border-blue-700");
    const droppedFiles = event.dataTransfer.files;
    const dataTransfer = new DataTransfer();

    // Add the newly dropped files
    Array.from(droppedFiles).forEach(file => {
      this.addFileToList(file);
      dataTransfer.items.add(file);
    });

    this.fileInputTarget.files = dataTransfer.files;

    this.addFiles();
  }

  selectFiles (event) {
    const files = event.target.files;

    // Add the newly selected files
    Array.from(files).forEach(file => {
      this.addFileToList(file);
    });

    this.addFiles();
  }

  addFileToList (file) {
    const listItem = this.fileDetailsTarget.cloneNode(true);

    listItem.id = `file_upload_${file.name}`;
    listItem.classList.remove("hidden");

    listItem.querySelector("#fileIcon").innerHTML = this.fileTypeIcon(file.type);

    listItem.querySelector("#fileName").prepend(file.name);

    this.fileListTarget.appendChild(listItem);
    this.toggleUploadContainer();
  }

  removeFile (evt) {
    evt.target.parentElement.parentElement.remove();
    this.toggleUploadContainer();
  }

  fileTypeIcon (type) {
    switch (type) {
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return this.slIcon("fad-file-xls");
      case "text/csv":
        return this.slIcon("fad-file-csv");
      case "application/pdf":
        return this.slIcon("fad-file-pdf");
      default:
        return this.slIcon("fad-file");
    }
  }

  slIcon (name) {
    return `<sl-icon library="fa" name=${name}></sl-icon>`;
  }

  updateFileCount () {
    const fileCount = this.uploadedFileListTarget.children.length;
    fileCount > 0 ? this.uploadedFilesContainerTarget.classList.remove("hidden") : this.uploadedFilesContainerTarget.classList.add("hidden");
    this.uploadedFileCountTarget.innerHTML = `${fileCount} ${fileCount !== 1 ? "files" : "file"} uploaded`;
  }

  toggleUploadContainer () {
    this.fileListTarget.children.length > 0 ? this.uploadContainerTarget.classList.remove("hidden") : this.uploadContainerTarget.classList.add("hidden");
  }

  moveFileToUploadedList (listItem) {
    listItem.querySelector("#fileIcon").classList.replace("text-red-700", "text-blue-600");
    listItem.querySelector("#errorMessage").classList.add("hidden");
    listItem.querySelector("#removeWrapper").classList.add("hidden");
    this.uploadedFileListTarget.append(listItem);
  }

  submit (event) {
    event.preventDefault();
    this.uploadButtonTarget.loading = true;

    const url = event.target.action;
    const fileListArr = Array.from(this.fileInputTargets.map((item) => Array.from(item.files))).flat();
    let fileCount = 0;
    fileListArr.forEach((file, _index) => {
      const fileName = file.name;
      const formData = new FormData();
      formData.append("file_path", file);
      fetch(url, {
        method: "POST",
        body: formData,
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": this.element.querySelector("input[name='authenticity_token']").value,
        },
        credentials: "same-origin",
      }).then(response => {
        fileCount++;
        const listItem = document.getElementById(`file_upload_${fileName}`);

        if (response.ok) {
          this.moveFileToUploadedList(listItem);
        } else {
          listItem.querySelector("#fileIcon").classList.replace("text-blue-600", "text-red-700");
          listItem.querySelector("#errorMessage").classList.remove("hidden");
        }

        if (fileCount === fileListArr.length) {
          this.uploadButtonTarget.loading = false;
          this.updateFileCount();
          this.toggleUploadContainer();
        }
      });
    });
  }
}
