import MicroModal from "micromodal/dist/micromodal";
window.MicroModal = MicroModal;

document.addEventListener("ajax:beforeSend", (e) => {
  if (e.target.dataset.toggle !== "modal") return;

  const [xhr, _options] = e.detail;
  xhr.setRequestHeader("x-custom-via", "modal");

  let { dismissible, closeOthers } = e.target.dataset;
  // Setting a value to true (without quotes) in Slim renders an empty string
  dismissible = (dismissible === "" ? true : dismissible);
  closeOthers = (closeOthers === "" ? true : closeOthers);

  if (dismissible) xhr.setRequestHeader("x-modal-dismissible", dismissible);
  if (closeOthers) xhr.setRequestHeader("x-modal-close-others", closeOthers);
});

// Handle injecting return value into DOM for any remote: true link w/ data-toggle="modal"
// (Stimulus's modal_controller.js will handle from there)
document.addEventListener("ajax:success", (e) => {
  if (e.target.dataset.toggle !== "modal") return;

  const [data, _status, xhr] = e.detail;
  const type = xhr.getResponseHeader("Content-Type");

  if (type.match(/^text\/html/)) {
    // Inject into DOM (assuming it's got the modal HTML -- rails should rending in the modal layout)
    document.querySelector("main").insertAdjacentHTML("beforeend", data.querySelector("body").innerHTML);
  } else if (type.match(/^application\/json/)) {
    try {
      if (data.location) {
        // Redirect to the new location
        window.location.href = encodeURI(data.location);
      }
    } catch (err) {
      console.error(err);
    }
  }
});
