import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["role", "button"];

  connect () {
    this.change();
  }

  change () {
    const role = this.roleTarget.value;

    if (role === "financial_editor") {
      this.buttonTarget.dataset.confirm = true;
    } else {
      // Strangely, false doesn't work here to disable the data confirm prompt, but an empty string does.
      this.buttonTarget.dataset.confirm = "";
    }
  }
}
