import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submit", "input"];

  fillPlaceholder () {
    const classList = this.inputTarget.classList;
    this.inputTarget.value ? classList.add("filled") : classList.remove("filled");
  }

  submit () {
    this.submitTarget.disabled = true;
  }
}
