import { Controller } from "@hotwired/stimulus";
import { employeeCertificates } from "./mixins/employee_certificates";

const disableElement = (el) => {
  el.setAttribute("disabled", "disabled");
  el.classList.add("disabled");
};

const enableElement = (el) => {
  el.removeAttribute("disabled");
  el.classList.remove("disabled");
};

export default class extends Controller {
  static targets = ["numSharesInput", "userInput", "shareClass", "reason"];

  connect () {
    employeeCertificates(this);
    this.setShareClass();
  }

  changeUser () {
    this.setShareClass();
    this.setReason();
    this.checkForAndRemoveErrorState();
  }

  setShareClass () {
    if (this._isFormerOwner()) {
      this.shareClassTarget.innerText = "Preferred";
    } else {
      this.shareClassTarget.innerText = "Common";
    }
  }

  setReason () {
    if (this._isFormerOwner()) {
      const issuanceReason = this.userInputTarget.dataset.issuanceReason;
      this.reasonTarget.value = issuanceReason;

      // HTMLOptionsCollection does not have a forEach method.
      // Workaround taken from:
      //   https://www.geeksforgeeks.org/htmlcollection-for-loop/
      Array.from(this.reasonTarget.options).forEach((option) => {
        if (option.value !== issuanceReason) {
          // Disabling specific options rather than the entire select element so that value is included in form submission
          disableElement(option);
        }
      });
    } else {
      Array.from(this.reasonTarget.options).forEach((option) => {
        enableElement(option);
      });
    }
  }

  _isPrez () {
    const leaderIds = (this.userInputTarget.dataset.leaders || "").split(" ");
    const selectedId = this.userInputTarget.value;

    return leaderIds.includes(selectedId);
  }

  _isFormerOwner () {
    const formerOwnerIds = (this.userInputTarget.dataset.formerOwners || "").split(" ");
    const selectedId = this.userInputTarget.value;

    return formerOwnerIds.includes(selectedId);
  }
}
