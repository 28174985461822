import { Controller } from "@hotwired/stimulus";

export default class EditCapTableFormController extends Controller {
  static targets = [
    "eventForm",
    "summaryPrompt",
    "summaryPromptCancelButton",
    "summaryPromptSubmitButton",
  ];

  static eventTypesWithoutUserInput = ["teamshares_buyback", "topup"];

  submitMultiStepForm () {
    const isErrorPresent = this._handleInputErrorState();

    if (!isErrorPresent) {
      this._showModal();
    }
  }

  _showModal () {
    const companyAbbreviation = document.getElementById("company_abbreviation").value;
    const form = document.getElementById("cap-table-edit-form");
    const formData = new URLSearchParams(new FormData(form)).toString();
    const element = this.element;

    Teamshares.Rails.ajax({
      url: `/companies/${companyAbbreviation}/cap_table_events/confirmations`,
      type: "GET",
      data: formData,
      success: (_data, _status, xhr) => {
        this.summaryPromptTarget.innerHTML = xhr.response;
        const confirmModal = element.querySelector("#event_form_confirmation_prompt");

        this.summaryPromptSubmitButtonTarget.onclick = () => {
          // Explicitly setting hide() callback on the dialog for a better user experience.
          confirmModal.hide();
          this.eventFormTarget.querySelector("#cap-table-edit-form").submit();
        };
        this.summaryPromptCancelButtonTarget.onclick = () => {
          confirmModal.hide();
        };

        confirmModal.show();
      },
    });
  }

  _addErrorUserState (selectElement) {
    const userSelectDiv = selectElement.parentElement;

    if (document.getElementById("user-input-error") == null) {
      const errorElement = document.createElement("div");

      errorElement.textContent = "must select an employee";
      errorElement.classList.add("error", "ts-input__help");
      errorElement.setAttribute("id", "user-input-error");
      userSelectDiv.appendChild(errorElement);
    }

    userSelectDiv.classList.add("error");
  }

  _handleInputErrorState () {
    let isError = false;

    if (this._isInValidUserInput()) {
      const userSelect = document.getElementById("event_data_certificate_user_id");
      this._addErrorUserState(userSelect);

      isError = true;
    }

    return isError;
  }

  _isInValidUserInput () {
    const eventType = document.getElementById("event_data_event_type").value;
    const userSelect = document.getElementById("event_data_certificate_user_id");
    const userId = userSelect?.value;

    return !EditCapTableFormController.eventTypesWithoutUserInput.includes(eventType) && (userId === "" || userId === undefined);
  }
}
