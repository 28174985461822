import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { url: String, useFirstLink: Boolean };

  connect () {
    if (!this.useFirstLinkValue && (!this.hasUrlValue || this.urlValue === "")) return;

    this.element.classList.add("cursor-pointer");

    // Note: hover:bg-blue-100 on the TR directly only works for half the rows (even/odd distinction)
    this.element.classList.add("group");
    this.element.querySelectorAll("td").forEach((td) => td.classList.add("group-hover:bg-blue-100"));

    this.boundHandleClick = this.handleClick.bind(this);
    this.element.addEventListener("click", this.boundHandleClick);
  }

  disconnect () {
    this.element.removeEventListener("click", this.boundHandleClick);
  }

  handleClick (event) {
    // Give clicks on ShareableUI::DropdownButton::Component precedence over clicks on the row
    const clickedOnDropdownBtn = event.target.closest(".js-dropdown");
    if (clickedOnDropdownBtn) return;

    if (this.useFirstLinkValue) {
      // Clicking anywhere in the row will result in clicking the first link inside the row.
      // This allows us to use the link_to_modal helper to provide modal open/close behavior.
      // Link needs to be inside a table cell because it's not valid HTML to wrap a table row or cell inside a link.
      this.element.querySelector("a").click();
    } else {
      window.location.href = this.urlValue;
    }
  }
}
