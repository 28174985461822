import { Controller } from "@hotwired/stimulus";

// [Highlights saved statements]
//
// Creating/updating weekly statements reloads the page with a saved=<id> URL hash,
// which this code uses to highlight the saved statement (should be modernized...).

const HIGHLIGHT_CLASS = "bg-yellow-300";
export default class HighlightSavedWeeklyStatementController extends Controller {
  connect () {
    const id = window.location.href.split("saved=").pop();
    if (!id) return;

    this.columnTds = document.querySelectorAll(`[data-column-id='${id}']`);
    this.highlightOn();
    setTimeout(this.highlightOff.bind(this), 2000);
  }

  highlightOn () {
    this.columnTds.forEach((node) => node.classList.add(HIGHLIGHT_CLASS));
  }

  highlightOff () {
    this.columnTds.forEach((node) => node.classList.remove(HIGHLIGHT_CLASS));
  }
}
