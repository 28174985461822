import { Controller } from "@hotwired/stimulus";

/*
 * Details List Controller - expand / collapse set of sl-details cards
 * ------------------
 * This controller should be attached to an element containing a set of sl-details elements.
 * It requires a `trigger` target that will toggle the list open or closed.
 * The default is to expand all; if we later end up needing a different default, we can add a `data-` attribute
 */

const LABELS = {
  expand: "Expand all",
  collapse: "Collapse all",
};

export default class extends Controller {
  static targets = ["trigger"];

  connect () {
    this.detailsList = this.element.querySelectorAll("sl-details");
    this.detailsList.forEach(el => {
      el.addEventListener("sl-show", this.updateTrigger);
      el.addEventListener("sl-hide", this.updateTrigger);
    });
    this.triggerTarget.addEventListener("click", this.toggle);
    this.updateTrigger();
  }

  disconnect () {
    this.detailsList.forEach(el => {
      el.removeEventListener("sl-show", this.updateTrigger);
      el.removeEventListener("sl-hide", this.updateTrigger);
    });
    this.triggerTarget.removeEventListener("click", this.toggle);
  }

  toggle = () => {
    const shouldExpand = this.triggerTarget.textContent === LABELS.expand;
    this.detailsList.forEach(el => {
      shouldExpand ? el.show() : el.hide();
    });
    this.updateTrigger();
  };

  /* If any of the elements are closed, then toggle should expand all */
  updateTrigger = () => {
    const allExpanded = Array.from(this.detailsList).every(el => el.open);
    this.triggerTarget.textContent = allExpanded ? LABELS.collapse : LABELS.expand;
  };
}
