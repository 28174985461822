import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  formatCurrency (event) {
    if (event.which >= 37 && event.which <= 40) event.preventDefault(); // skip for arrow keys

    const input = event.target;
    const decimalCount = this.testDecimals(input.value);
    if (decimalCount > 1) input.value = input.value.slice(0, -1);

    input.value = this.replaceCommas(input.value);
  }

  testDecimals (value) {
    const count = value.match(/\./g) === null ? 0 : value.match(/\./g).length;
    return count;
  }

  replaceCommas (value) {
    const components = value.split(".").map(component => component.replace(/\D/g, ""));
    components[0] = components[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (components.length > 1) components[1] = components[1].slice(0, 2);

    return components.join(".");
  }
}
