import { Controller } from "@hotwired/stimulus";

const modalBgClass = "opacity-100";
const modalSelector = "[data-controller='modal']";
const modalTransitionInClasses = ["opacity-100", "translate-y-4", "sm:translate-y-0", "sm:scale-95"];

const removeOtherModals = (keep) => Array.from(document.querySelectorAll(modalSelector)).forEach(node => node === keep || node.remove());

// NOTE: node.toggle.toggleControllerTargets is defined in the shared engine's toggle_controller.js
const removeFlashAlerts = () => Array.from(document.getElementsByClassName("flash-message")).forEach(node => {
  return node.toggle.classIsApplied() ? null : node.toggle.toggleControllerTargets();
});

// Handles opening modals (via micromodal) whenever the relevant nodes appear in the DOM
export default class extends Controller {
  static targets = ["close", "background", "content"];

  static values = {
    dismissible: Boolean,
    closeOthers: Boolean,
  };

  connect () {
    this.closeTargets.forEach((elem) => {
      elem.addEventListener("click", () => this.closeModal());
    });

    this.openModal();
    this._focusDefaultElement();
    window.addEventListener("keydown", this._handleEsc);
  }

  disconnect () {
    this.closeModal();
    window.removeEventListener("keydown", this._handleEsc);
  }

  backgroundClickHandler = () => {
    this._handleClose();
  };

  closeModal = () => {
    this.backgroundTarget.classList.remove(modalBgClass);
    this.contentTarget.classList.remove(...modalTransitionInClasses);

    setTimeout(() => this.element.remove(), 300);
  };

  openModal () {
    if (this.closeOthersValue) {
      removeOtherModals(this.element);
      removeFlashAlerts();
    }

    this.backgroundTarget.classList.add(modalBgClass);
    this.contentTarget.classList.add(...modalTransitionInClasses);
  }

  _handleClose = () => {
    if (this.dismissibleValue) {
      this.closeModal();
    } else {
      // TODO: add fancy UI (e.g. bounce scale or shake) to indicate click received but cannot dismiss modal
    }
  };

  _handleEsc = (evt) => {
    if (evt.key === "Escape" || evt.key === "Esc") {
      this._handleClose();
    }
  };

  _focusDefaultElement () {
    const focusEl = document.querySelector("[data-error='true']") ||
      this.element.querySelector("[data-focus-default='true']");
    if (!focusEl) return;

    focusEl.selectionStart = 0;
    focusEl.selectionEnd = focusEl.value.length;
    focusEl.focus();
  }
}
