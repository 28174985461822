import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect () {
    this._getDetailsElements()[0].open = true;
    this.element.addEventListener("sl-show", this.handleDetailsToggle);
  }

  disconnect () {
    this.element.removeEventListener("sl-show", this.handleDetailsToggle);
  }

  handleDetailsToggle = (event) => {
    this._getDetailsElements().map(details => (details.open = event.target === details));
  };

  _getDetailsElements = () => {
    return [...this.element.querySelectorAll("sl-details")];
  };
}
