// Initialization
import * as ActiveStorage from "@rails/activestorage";
import MicroModal from "micromodal";

// Internal helpers/includes;
import "./includes/modal";

import Teamshares from "@teamshares/design-system/rails-js";
Teamshares.start({ disableTurboSessionDrive: true });

ActiveStorage.start();

// This handles the case of missing turbo-frame targets by just reloading the page
// (which turbo did automatically prior to 7.3.0)
document.addEventListener("turbo:frame-missing", (event) => {
  const { detail: { response, visit } } = event;
  console.warn("Missing turbo-frame target:", event.target);
  event.preventDefault();
  visit(response.url);
});

// -- BEGIN prompt code -- to be replaced with shoelace -- //
// Note this was an include, but those are hoisted and we need to run Teamshares.start first

// This is the native confirm, showing a browser alert/confirm dialog
const nativeConfirm = Teamshares.Rails.confirm;

Teamshares.Rails.confirm = (message, element) => {
  // Here is the logic to determine if a custom dialog should be shown. In
  // this case, we'd expect [data-confirm-prompt="id"] to be present, but
  // other techniques, such as dynamically building a dialog from the
  // [data-confirm] content would also work.
  const promptId = element.getAttribute("data-confirm-prompt");
  if (!promptId) {
    return nativeConfirm(message, element);
  }

  showPrompt(promptId);
  return false;
};

function showPrompt (promptId) {
  const dialog = document.getElementById(promptId);
  if (dialog.tagName === "SL-DIALOG") {
    dialog.querySelector("sl-button.cancel").addEventListener("click", () => {
      dialog.hide();
    });
    dialog.show();
  } else {
    MicroModal.show(promptId);
  }
}

// -- END prompt code -- //
