import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "idInput",
    "emailInput",
    "fullNameInput",
  ];

  changeUser () {
    this.resetFormUserId();
    this.resetUserEmail();
  }

  resetFormUserId () {
    const newId = this.fullNameInputTarget.value;
    this.idInputTarget.value = newId;
  }

  resetUserEmail () {
    const selectedOptionIndex = this.fullNameInputTarget.selectedIndex;
    const selectedOption = this.fullNameInputTarget.options[selectedOptionIndex];
    const newEmail = selectedOption.attributes["data-user-email"].value;

    this.emailInputTarget.value = newEmail;
  }
}
