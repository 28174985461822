import { Controller } from "@hotwired/stimulus";

export default class RepurchaseOptionsController extends Controller {
  static targets = [
    "repurchaseOption",
    "repurchaseMessage",
    "confirmationPrompt",
  ];

  initialize () {
    this.changeRepurchaseOption();
  }

  changeRepurchaseOption () {
    if (!this.hasRepurchaseOptionTarget || this.repurchaseOptionTarget.checked === true) {
      this.repurchaseMessageTarget.innerText = " available for repurchase 6 months after last vesting date.";
    } else {
      this.repurchaseMessageTarget.innerText = " will be kept in the cap table for up to 5 years";
    }
  }

  submitEmployeeDeparture () {
    this.confirmationPromptTarget.show();
  }
}
