import { Controller } from "@hotwired/stimulus";

const DisallowedCharacters = /[^\d.-]/g;
const NonFirstCharacterNegative = /(.)-+/g;

export default class extends Controller {
  static targets = ["input"];

  static values = { decimals: Number };

  select () {
    this.inputTarget.select();
  }

  connect () {
    if (this.inputTarget.value) this.reformat();
  }

  reformat (event) {
    // Skip reformatting when user just entered "." so user can enter decimals
    if (event && event.inputType === "insertText" && event.data === ".") return;

    // Note: gets weird after trillions (presumably parseFloat limits?) but solid for our purposes
    const stripped = this.inputTarget.value.replace(DisallowedCharacters, "").replace(NonFirstCharacterNegative, "$1");

    // Allow entering negative numbers
    if (stripped === "-") {
      this.inputTarget.value = "-";
      return;
    }

    const asFloat = parseFloat(stripped, 10);

    if (isNaN(asFloat)) {
      this.inputTarget.value = "";
    } else if (stripped.slice(stripped.length - 2) === ".0") {
      this.inputTarget.value = stripped; // Allow entering decimals with a 0 in the tenths place (otherwise toLocaleString strips to an integer)
    } else {
      this.inputTarget.value = asFloat.toLocaleString("en-US", { maximumFractionDigits: this.decimalsValue || 10 });
    }
  }
}
