import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "companyName",
    "dialogCancelButton",
    "dialogRejectButton",
    "distributionAmount",
    "rejectDialog",
    "rejectForm",
    "textArea",
  ];

  connect () {
    this.addRejectDistributionListeners();
    this.addDialogRejectButtonListeners();
    this.addDialogCancelButtonListeners();
  }

  addDialogCancelButtonListeners () {
    this.dialogCancelButtonTarget.addEventListener("click", () => this.rejectDialogTarget.hide());
    this.dialogCancelButtonTarget.addEventListener("click", () => { this.textAreaTarget.value = ""; });
  }

  addDialogRejectButtonListeners () {
    this.dialogRejectButtonTarget.addEventListener("click", () => this.rejectDialogTarget.hide());
    this.dialogRejectButtonTarget.addEventListener("click", () => { this.textAreaTarget.value = ""; });
  }

  addRejectDistributionListeners () {
    const rejectButtons = document.getElementsByClassName("reject-request-button");

    Array.from(rejectButtons).forEach((button) => {
      button.addEventListener("click", () => this.rejectDialogTarget.show());
      button.addEventListener("click", () => this.setFormData(button));
    });
  }

  setFormData (button) {
    const currentEl = button;
    let previousSibling = currentEl.previousElementSibling;

    while (previousSibling) {
      const siblingId = previousSibling.id;

      switch (siblingId) {
        case "distribution_amount":
          this.setDistributionAmount(previousSibling);
          break;
        case "company_name":
          this.setCompanyName(previousSibling);
          break;
        case "distribution_id":
          this.setFormSubmissionPath(previousSibling);
          break;
      }

      previousSibling = previousSibling.previousElementSibling;
    }
  }

  setCompanyName (element) {
    const companyName = element.value;

    this.companyNameTarget.innerHTML = companyName;
  }

  setDistributionAmount (element) {
    const distributionAmount = element.value;

    this.distributionAmountTarget.innerHTML = distributionAmount;
  }

  setFormSubmissionPath (element) {
    const distributionId = element.value;
    const sumbmissionPath = `/admin/distribution_requests/${distributionId}/reject`;

    this.rejectFormTarget.action = sumbmissionPath;
  }
}
