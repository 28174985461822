import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["to"];

  copy (event) {
    this.toTargets.forEach((to) => {
      if (event.target.dataset.name === to.dataset.name) to.value = event.target.value;
    });
  }
}
