import { Controller } from "@hotwired/stimulus";
import ConfettiGenerator from "confetti-js";

import confettoRed from "../images/os-confetti-red.svg";
import confettoBlue from "../images/os-confetti-blue.svg";
import confettoLightBlue from "../images/os-confetti-light-blue.svg";
import confettoGreen from "../images/os-confetti-green.svg";
import confettoYellow from "../images/os-confetti-yellow.svg";

const ListofConfettiSVG = [
  { type: "svg", src: confettoRed, size: 70, weight: 1 / 5 },
  { type: "svg", src: confettoBlue, size: 75, weight: 1 / 5 },
  { type: "svg", src: confettoLightBlue, size: 65, weight: 1 / 5 },
  { type: "svg", src: confettoYellow, size: 60, weight: 1 / 5 },
  { type: "svg", src: confettoGreen, size: 70, weight: 1 / 5 },
];

const ListOfDefaultShapes = ["circle", "square", "triangle", "line"];

export default class extends Controller {
  connect () {
    const isFirefox = typeof InstallTrigger !== "undefined";
    const confettiProps = isFirefox ? ListOfDefaultShapes : ListofConfettiSVG;

    const canvasEl = this.element;
    const confettiSettings = {
      target: canvasEl,
      rotate: true,
      animate: true,
      max: 80,
      clock: 27,
      respawn: false,
      fps: 17,
      props: confettiProps,
      colors: [[165, 104, 246], [230, 61, 135], [0, 199, 228], [253, 214, 126]],
    };
    const confetti = new ConfettiGenerator(confettiSettings);
    confetti.render();
  }
}
