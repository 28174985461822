import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["chart"];

  connect () {
    this.link = this.element.querySelector("sl-button");
    this.boundToggleChart = this.toggleChart.bind(this);
    this.link.addEventListener("click", this.boundToggleChart);
  }

  disconnect () {
    this.link.removeEventListener("click", this.boundToggleChart);
  }

  toggleChart (e) {
    e.preventDefault();
    this.chartTarget.classList.toggle("hidden");
  }
}
