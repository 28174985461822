import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select"];

  connect () {
    this.selectTarget.addEventListener("sl-change", this.onChange);
  }

  disconnect () {
    this.selectTarget.removeEventListener("sl-change", this.onChange);
  }

  onChange = (e) => {
    const turboFrameId = this.element.getAttribute("data-turbo-frame-id");
    if (turboFrameId) {
      const frame = document.getElementById(turboFrameId);
      frame.setAttribute("src", e.target.value);
    } else {
      window.location.href = e.target.value;
    }
  };
}
