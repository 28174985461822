import { Controller } from "@hotwired/stimulus";

export default class EmployeeDepartureController extends Controller {
  static targets = [
    "reason",
    "hasTenure",
    "hasTenureInput",
    "isRetirementAge",
    "isRetirementAgeInput",
    "repurchaseOption",
    "repurchaseMessage",
    "confirmationPrompt",
    "employeeDepartureForm",
    "confirmationCancelButton",
    "confirmationSubmitButton",
  ];

  initialize () {
    this.handleReasonChange();
  }

  handleReasonChange () {
    const reasonValue = this.reasonTarget.value;
    if (reasonValue === "Retirement") {
      this.hasTenureTarget.classList.remove("hidden");
      this.isRetirementAgeTarget.classList.remove("hidden");
    } else {
      this.hasTenureTarget.classList.add("hidden");
      this.isRetirementAgeTarget.classList.add("hidden");

      this.isRetirementAgeInputTargets.forEach(input => {
        this.clearCheckedTarget(input);
      });

      this.hasTenureInputTargets.forEach(input => {
        this.clearCheckedTarget(input);
      });
    }
  }

  clearCheckedTarget (input) {
    input.checked = false;
  }

  changeRepurchaseOption () {
    if (this.repurchaseOptionTarget.value === true) {
      this.repurchaseMessageTarget.innerText = " available for repurchase 6 months after last vesting date.";
    } else {
      this.repurchaseMessageTarget.innerText = " will be kept in the cap table for up to 5 years";
    }
  }

  submitEmployeeDeparture () {
    this.confirmationPromptTarget.show();
  }

  cancelSubmitEmployeeDeparture () {
    this.confirmationPromptTarget.hide();
  }

  confirmSubmitEmployeeDeparture () {
    this.employeeDepartureFormTarget.submit();
  }
}
