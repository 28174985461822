import { Controller } from "@hotwired/stimulus";
import parseTextualNumber from "../../lib/parseTextualNumber";

export default class extends Controller {
  static targets = ["currentTotalShares", "newTotalShares", "sharesToAdd", "currentValuation", "newSharePrice"];
  static values = { currentValuation: Number };

  updateNewShares () {
    const currentTotalShares = parseTextualNumber(this.currentTotalSharesTarget.innerHTML);
    const sharesToAdd = parseTextualNumber(this.sharesToAddTarget.value);
    const currentValuation = parseTextualNumber(this.currentValuationValue);

    const newTotalShares = currentTotalShares + sharesToAdd;
    const newSharePrice = currentValuation / newTotalShares;

    this.newTotalSharesTarget.innerHTML = newTotalShares.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });

    this.newSharePriceTarget.innerHTML = newSharePrice.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: "currency",
      currency: "USD",
    });
  }
}
