import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="distributions--dividend-summary"
export default class extends Controller {
  static targets = [
    "dividendDialog",
  ];

  openDividendDialog (e) {
    this.dividendDialogTarget.show();
    this.preventDetailsToggle(e);
  }

  preventDetailsToggle (e) {
    e.stopPropagation();
  }
}
