import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "forfeitDialog",
    "forfeitForm",
    "textArea",
  ];

  showForfeitDialog () {
    this.forfeitDialogTarget.show();
  }

  hideForfeitDialog () {
    this.forfeitDialogTarget.hide();
  }

  submitForfeiture () {
    const note = this.textAreaTarget.value;

    if (note) {
      this.hideForfeitDialog();
      this.forfeitFormTarget.requestSubmit();
    } else {
      this.textAreaTarget.focus();
    }
  }
}
