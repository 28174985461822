import { Controller } from "@hotwired/stimulus";
import parseTextualNumber from "../../lib/parseTextualNumber";

export default class extends Controller {
  static targets = ["numSharesToBuyBack", "currentTotal", "newTotal"];

  connect () {
    this.updateNewTeamsharesSharesValue();
  }

  changeNumShares () {
    this.checkForAndRemoveNumSharesErrorState();
    this.updateNewTeamsharesSharesValue();
  }

  checkForAndRemoveNumSharesErrorState () {
    const numSharesInput = document.getElementById("event_data_cap_table_event_num_shares");
    const numSharesInputValue = numSharesInput.value;
    const numSharesInputDiv = numSharesInput.parentElement;

    if (numSharesInputValue !== "" && numSharesInputDiv.classList.contains("error")) {
      const userInputErrorDiv = document.getElementById("num-share-input-error");

      userInputErrorDiv.remove();
      numSharesInputDiv.classList.remove("error");
    }
  }

  updateNewTeamsharesSharesValue () {
    const numSharesToBuyBack = parseTextualNumber(this.numSharesToBuyBackTarget.value) || 0;
    const originalTSShareAmount = parseTextualNumber(this.currentTotalTarget.textContent) || 0;
    const newTSSharesTotal = (originalTSShareAmount - numSharesToBuyBack);

    this.newTotalTarget.textContent = newTSSharesTotal.toLocaleString();
  }
}
