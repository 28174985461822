export const employeeCertificates = controller => {
  Object.assign(controller, {
    checkForAndRemoveErrorState () {
      const userSelect = document.getElementById("event_data_certificate_user_id");
      const userSelectDiv = userSelect.parentElement;

      if (!this.isUserBlank() && userSelectDiv.classList.contains("error")) {
        const userInputErrorDiv = document.getElementById("user-input-error");

        userInputErrorDiv.remove();
        userSelectDiv.classList.remove("error");
      }
    },

    createOption (text, value) {
      const opt = document.createElement("option");
      opt.text = text;
      opt.value = value;

      return opt;
    },

    isUserBlank () {
      const userId = document.getElementById("event_data_certificate_user_id").value;

      return userId === "";
    },

    resetBlankUserCertOptions (selectElement) {
      const blankOption = this.createOption("", "");

      Array.from(selectElement).forEach((option) => { selectElement.removeChild(option); });
      selectElement.appendChild(blankOption);
    },
  });
};
