import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["slDialog", "noInsurance", "yesInsurance"];

  connect () {
    this.slDialogTarget.addEventListener("sl-request-close", (event) => {
      event.preventDefault();
    });
  }

  submitNo () {
    this.noInsuranceTarget.submit();
  }

  submitYes () {
    this.yesInsuranceTarget.submit();
  }
}
