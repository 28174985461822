import { Controller } from "@hotwired/stimulus";

// Handles monthly charts page actions - we reference the hard coded "monthly-chards" ID and "charts-card" class in this controller
export default class extends Controller {
  static targets = ["metric", "button", "pointer", "card"];

  toggleDatasetIndex () {
    this.toggleChart();
    this.toggleLabel();
    this.toggleStyling();
    // If all cards are toggled off, toggle on revenue so the chart isn't blank
    if (document.getElementsByClassName("charts-card enabled").length === 0) {
      this.toggleFirstCard();
    }
  }

  toggleStyling () {
    this.cardTarget.classList.toggle("enabled");
  }

  toggleChart () {
    // eslint-disable-next-line no-undef
    const chart = Chartkick.charts["monthly-chart"].getChartObject();
    // Note: the chartJS function toggleDataVisibility doesn't work for line charts, and getDataVisibility doesn't work as well so below is a workaround
    const visible = this.cardTarget.classList.contains("enabled");
    let datasetToggleIndex;
    // Find the index of our metric in the chart dataset
    chart.data.datasets.forEach((dataset, idx) => {
      if (dataset.label === this.metricTarget.innerText) {
        datasetToggleIndex = idx;
      }
    });
    chart.setDatasetVisibility(datasetToggleIndex, !visible);
    chart.update();
  }

  toggleFirstCard () {
    document.getElementsByClassName("charts-card")[0].getElementsByTagName("button")[0].click();
  }

  toggleLabel () {
    document.getElementById(this._labelID()).classList.toggle("hidden");
  }

  _labelID () {
    return this.metricTarget.innerText.toLowerCase().replaceAll(" ", "_") + "_label";
  }
}
