import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { feature: Boolean };

  onVisibilityChange () {
    if (document.visibilityState === "visible") {
      this.reloadForSessionChanges();
    }
  }

  onWindowFocus () {
    this.reloadForSessionChanges();
  }

  async reloadForSessionChanges () {
    try {
      if (!this.featureValue) return;

      const response = await fetch("/auth_zero_session_changes/new");

      // Server may return HTTP 304 Not Modified for unchanged JSON on staging or production
      if (response.status === 304) return;

      const responseJson = await response.json();

      if (responseJson && responseJson.sessionChanged) window.location.reload();
    } catch (err) {
      window.Honeybadger && window.Honeybadger.notify("Error trying to retrieve session changes: " + err);
    }
  }
}
