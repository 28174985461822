import { Controller } from "@hotwired/stimulus";

export default class FadeController extends Controller {
  static targets = ["fadeable"];

  connect () {
    setTimeout(this.fadeOut.bind(this), 2000);
  }

  fadeOut () {
    this.fadeableTarget.addEventListener("transitionend", this.removeElement.bind(this), true);
    this.fadeableTarget.classList.add("opacity-0");
  }

  removeElement () {
    this.fadeableTarget.classList.add("hidden");
  }
}
