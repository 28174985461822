import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["drawer", "trigger"];

  connect () {
    this.boundshowDrawer = this.showDrawer.bind(this);
    this.triggerTarget.addEventListener("click", this.boundshowDrawer);
  }

  disconnect () {
    this.triggerTarget.removeEventListener("click", this.boundshowDrawer);
  }

  showDrawer (e) {
    e.preventDefault();
    this.drawerTarget.show();
  }

  closeDrawer (e) {
    e.preventDefault();
    this.drawerTarget.hide();
  }
}
