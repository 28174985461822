import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static filled = "filled";

  static targets = ["email", "submit", "defaultSignIn", "defaultSignInForm", "redirectMessage"];

  static values = {
    autoSubmit: Boolean,
  };

  connect () {
    this.fillPlaceholder();
    this.autoSubmit();
  }

  disable () {
    this.emailTarget.readonly = true;
    this.submitTarget.disabled = true;
  }

  fillPlaceholder = () => {
    const classList = this.emailTarget.classList;
    const filled = this.constructor.filled;

    this.emailTarget.value ? classList.add(filled) : classList.remove(filled);
  };

  autoSubmit = () => {
    if (this.autoSubmitValue) {
      this.defaultSignInTarget.classList.add("hidden");
      this.redirectMessageTarget.classList.remove("hidden");
      setTimeout(() => this.defaultSignInFormTarget.submit(), 200);
    }
  };
}
