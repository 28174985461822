// Pairs roughly with our custom :textual_number config for Normalizr

const parseTextualNumber = (text) => {
  if (typeof text === "number" || typeof text === "undefined") return text;

  // Two-step parsing to avoid figuring out regex complexity (negative lookbehinds?) to allow - only as first character
  const correctChars = (text || "").replace(/[^\d.-]/g, "");
  const sign = correctChars[0] === "-" ? -1 : 1;

  // Remove dashes... also leading or trailing or repeating dots... should get us to a mostly sane place
  // (could still have multiple separate decimals, but at some point of complexity what's the user thinking?)
  const normalized = correctChars.replace(/-/g, "").replace(/^\.+/g, "").replace(/\.+$/g, "").replace(/\.{2,}/g, ".");
  const baseNum = Number(normalized);

  return sign * baseNum;
};

export default parseTextualNumber;
